<template>
  <div>
    <div class="main" v-show="!isShowDetail">
      <div class="top">
        <p class="top-left">
          <i class="el-icon-back" style="margin-right: 20px" @click="goBack"></i>
          <span>{{ title }}</span>
        </p>
        <p class="top_right"><span class="xinxin">*</span> 为必填项</p>
      </div>
      <el-divider></el-divider>
      <div class="content" v-loading="loading">
        <div class="title">
          <span class="blue"></span>
          <p>任务基本信息</p>
        </div>
        <!-- 基本信息 -->
        <el-form :model="taskInfo" :rules="taskInfoRules" ref="taskInfoRef" label-width="120px">
          <div class="content-item">
            <el-form-item class="formitem" label="信息来源:" prop="infoSourceCode">
              <el-cascader v-if="echoAddress" class="select-width" ref="infoSourceRef" v-model="taskInfo.infoSourceCode"
                :props="{ ...props, checkStrictly: true }" @change="handleInfoSource" clearable></el-cascader>
            </el-form-item>
            <el-form-item class="formitem" label="信息类别:" prop="infoType">
              <el-radio-group v-model="taskInfo.infoType">
                <el-radio v-for="item in infoTypeSelect" :label="item.value" :key="item.value">{{ item.label }}
                </el-radio>
                <el-input v-show="taskInfo.infoType == 3" class="other-input" v-model="taskInfo.infoTypeOther"
                  placeholder="请输入其他信息类别"></el-input>
                <el-input v-show="taskInfo.infoType == 1" class="other-input" v-model="taskInfo.letterNo"
                  placeholder="协查函编号" :maxlength="32"></el-input>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item label="事件名称:" class="formitem">
              <el-select class="select-width" v-model="taskInfo.eventId" clearable placeholder="请选择"
                @clear="clearEventId">
                <el-option v-for="item in eventNameSelect" :key="item.id" :label="item.eventName" :value="item.id"
                  @click.native="handelEventChang(item)"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="任务描述:">
              <el-input type="textarea" v-model="taskInfo.taskDesc" class="select-width" placeholder="请输入内容"
                maxlength="200" :rows="5" show-word-limit></el-input>
            </el-form-item>
          </div>
          <div class="content-item">
            <el-form-item class="special" label="附件:">
              <el-upload class="select-width" :action="upload" accept=".jpg, .png, .doc, .docx, .pdf, .xls, .xlsx"
                :before-upload="beforeUpload" :on-preview="handlePreview" :before-remove="beforeRemove"
                :on-remove="handleRemove" :on-success="handelSuccess" :on-error="handelError" :headers="myHeaders"
                :file-list="fileList">
                <el-button size="small" icon="el-icon-upload" v-if="preTaskStatus != 2">上传文件</el-button>
                <span slot="tip" class="el-upload-tip">
                  支持格式：.jpg .png .doc .docx .pdf .xls
                  .xlsx，单个文件不能超过20MB1
                </span>
              </el-upload>
            </el-form-item>
          </div>
          <!-- 居民信息 -->
          <resident-basic-info ref="residentBasicInfoRef" :type="type"
            :surveyGroupUserListSelect="surveyGroupUserListSelect" :userPlaceSelect="userPlaceSelect"
            :preTaskStatus="taskInfo.preTaskStatus" :myHeaders="myHeaders" @showDetail="showDetail">
          </resident-basic-info>
          <div class="submit-btn">
            <el-button type="primary" @click="submitForm">提交</el-button>
          </div>
        </el-form>
        <!-- 提交对话框 -->
        <dialog-info :dialogVisible="dialogVisible" title="警告提示" @closeDialog="closeDialog"
          @confirmDialog="confirmCloseDialog">
          <el-row class="warning" type="flex" align="middle">
            <i class="el-icon-warning-outline" style="
                color: #ff9900;
                font-size: 26px;
                font-weight: bold;
                margin-right: 10px;
              "></i>
            <span style="font-weight: bold; font-size: 16px">当前登录居民信息疑似已入住隔离点，请联系核实</span>
          </el-row>

          <div class="notification" v-for="(item, index) in repeteResidents" :key="index">

            <div class="NameNotification">
              {{ item.name }}
            </div>
            <div class="CardIdNotification">
              {{ item.cardNo }}
            </div>
            <div class="OrgPhoneNotification">
              {{item.org}}({{ item.linkPhone ? item.linkPhone : "-" }})
            </div>
            <div class="TypeNotification">
              <span v-if="item.type=='隔离'"><el-tag size="mini">隔离</el-tag></span>
              <span v-else><el-tag type="danger" size="mini">流调</el-tag></span>
            </div>
            <!-- <el-row :gutter="20" v-for="(item, index) in repeteResidents" :key="index">
            <el-col :span="3" :offset="2">{{ item.name }}</el-col>
            <el-col :span="8">{{ item.cardNo }}</el-col>
            <el-col :span="10">
              {{ item.org }}({{ item.linkPhone ? item.linkPhone : "-" }})
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span v-if="item.type=='隔离'"><el-tag size="mini">隔离</el-tag></span>
              <span v-else><el-tag type="danger" size="mini">流调</el-tag></span>
            </el-col>
          </el-row> -->
          </div>
          
        </dialog-info>
      </div>
    </div>
    <!-- 居民详情 -->
    <div v-show="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
import { _debounce } from '@/utils/utils.js'
import { Loading } from "element-ui";
import {
  savePreTaskApi,
  getSurveyGroupUserListApi,
  getPreTaskInfoByIdApi,
  updatePreTaskApi,
  getPlaceListApi,
  checkPreTaskApi,
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import DialogInfo from "@/components/DialogInfo";
import ResidentBasicInfo from "./components/ResidentBasicInfo";
import { getToken } from "@/utils/auth";
import { INFO_TYPE } from "@/common/constants";
import { downloadExportFile } from "@/utils/utils";
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
export default {
  name: "AddSurveyTask",
  components: { DialogInfo, ResidentBasicInfo, residentInformation },
  props: ["props", "eventNameSelect", "type", "id"],
  data() {
    return {
      title: "任务信息",
      isShowDetail: false, //是否展示居民详情
      echoAddress: false,
      upload: "/ohealth/api/v1/system/common/uploadFile", //文件上传地址
      userPlaceSelect: [], //涉事场所选项
      surveyGroupUserListSelect: [], //调查人员项
      infoTypeSelect: INFO_TYPE, //信息类别
      preTaskStatus: "", //任务状态
      loading: false, //加载框
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
      taskInfo: {
        infoSourceCode: [], //信息来源
        infoSource: "", //信息来源
        infoType: "1", //信息类别 ，默认协查函
        infoTypeOther: "", //信息类别其他
        eventName: "", //事件名称
        eventId: "", //事件id
        taskDesc: "", //任务描述,
      },
      fileList: [], //回显时文件列表
      tempFileList: [], //备份文件列表
      dialogVisible: false, //提示框
      repeteResidents: [], //重复的居民信息
      taskInfoRules: {
        infoSourceCode: [
          { required: true, message: "请输入信息来源", trigger: "change" },
        ],
        infoType: [
          { required: true, message: "请选择信息类别", trigger: "change" },
        ],
      }, //任务表单规则
    };
  },
  created() {
    this.echoAddress = this.type == "edit" ? false : true;
    this.getWaitSurveyList();
    //编辑
    if (this.type == "edit") {
      this.getPreTaskInfoById();
    }
  },
  watch: {
    "taskInfo.eventId"(newVal) {
      if (newVal) this.userPlaceList();
    },
  },
  methods: {
    // 返回上一页面
    goBack() {
      this.$parent.isAddTask = false;
    },
    //获取任务详情
    async getPreTaskInfoById() {
      const params = { id: this.id };
      const { data } = await getPreTaskInfoByIdApi({ params });
      if (data.code == "200") {
        this.renderData(data.data);
        this.preTaskStatus = data.data.info.preTaskStatus;
      }
    },
    //处理数据
    renderData(data) {
      const { fileList, info, userList } = data;
      this.taskInfo = { ...info };
      //信息来源
      this.taskInfo.infoSourceCode = info.infoSourceCode.split(",");
      this.echoAddress = true;
      //涉事场所回显处理
      this.$refs.residentBasicInfoRef.userListForm.userList = userList.map(
        (item) => {
          item.placeIds = item.placeList.map((place) => {
            return { id: place.placeInvolvedId };
          });
          return item;
        }
      );
      //用于回显文件列表
      this.fileList = fileList.map((item) => {
        return {
          name: item.fileName,
          url: item.fileUrl,
        };
      });
      //文件列表，用于更新文件列表
      this.tempFileList = JSON.parse(JSON.stringify(this.fileList));
    },
    //选择信息来源
    handleInfoSource(value) {
      let node = this.$refs.infoSourceRef.getCheckedNodes()[0];
      if (node) {
        let infoSource = node.pathLabels.toString();
        this.taskInfo.infoSource = infoSource;
      }
    },
    //选择事件名称
    handelEventChang(item) {
      this.taskInfo.eventId = item.id;
      this.taskInfo.eventName = item.eventName;
      //清空流调人员涉事场所
      this.$refs.residentBasicInfoRef.userListForm.userList =
        this.$refs.residentBasicInfoRef.userListForm.userList.map((item) => {
          item.placeIds = [];
          item.placeList = [];
          return item;
        });
    },
    //清空事件名称
    clearEventId() {
      this.taskInfo.eventId = "";
      this.taskInfo.eventName = "";
      //清空流调人员涉事场所
      this.$refs.residentBasicInfoRef.userListForm.userList =
        this.$refs.residentBasicInfoRef.userListForm.userList.map((item) => {
          item.placeIds = [];
          item.placeList = [];
          return item;
        });
    },
    //上传文件大小限制
    beforeUpload(file) {
      let size = file.size / 1024 / 1024 < 20;
      if (!size) {
        this.$message.warning("上传文件大小不能超过20M");
        return false;
      }
    },
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.tempFileList.push({
          name: file.response.data.originalName,
          url: file.response.data.imgUrl,
        });
      }
    },
    //文件上传失败
    handelError(error) {
      if (res.code == 200) {
        this.$message({
          message: "上传失败",
          type: "error",
        });
      }
    },
    //文件删除询问
    beforeRemove() {
      return this.$confirm("确定要删除文件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
    //文件删除
    handleRemove(file) {
      if (file.status != "success") return;
      const deleteIndex = this.tempFileList.findIndex((item) => {
        if (file.response) {
          return item.url == file.response.data.imgUrl;
        } else {
          return item.url == file.url;
        }
      });
      this.tempFileList.splice(deleteIndex, 1);
    },
    //文件下载
    handlePreview(file) {
      let loadingInstance = Loading.service();
      Loading.service();

      if (file.status != "success") return;
      let fileUrl;
      if (file.response) {
        fileUrl = file.response.data.imgUrl;
      } else {
        fileUrl = file.url;
      }
      //获取文件后缀
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (["png", "jpg"].includes(suffix)) {
        let alink = document.createElement("a");
        alink.href = fileUrl;
        alink.target = "_blank";
        alink.download = file.name; //fileName保存提示中用作预先填写的文件名
        alink.click();
      } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
        console.log(2);
        downloadExportFile(fileUrl, file.name, suffix);
      } else {
        return;
      }
      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },

    //获取涉事场所列表
    async userPlaceList() {
      const params = { eventId: this.taskInfo.eventId };
      const { data } = await getPlaceListApi({ params });
      if (data.code == "200") {
        this.userPlaceSelect = data.data;
      }
    },
    //查询待流调人员列表
    async getWaitSurveyList() {
      const params = {};
      const { data } = await getSurveyGroupUserListApi({ params });
      if (data.code == "200") {
        this.surveyGroupUserListSelect = data.data;
      }
    },
    //提交
    submitForm:_debounce(function() {
      console.log(5666);
      const taskInfoValidate = this.$refs.taskInfoRef.validate();
      const residentValidate = this.$refs.residentBasicInfoRef.comm();
      Promise.all([taskInfoValidate, residentValidate])
        .then((res) => {
          if (!this.verifyFormInfo()) return;
          this.commData = this.handelCommData();
          this.checkPreTask(this.commData);
        })
        .catch((err) => {
          this.$message({
            message: "必填项未填写或填写格式不符合",
            type: "warning",
            offset: 60,
          });
        });
    },300),
    //提交前任务居民是否存在校验
    async checkPreTask(commData) {
      const params = commData;
      const { data } = await checkPreTaskApi(params);
      if (data.code == "200") {
        // console.log('验证走到387行')
        if (data.data) {
          this.repeteResidents = data.data;
          this.dialogVisible = true;
          // console.log(this.dialogVisible,'验证弹窗状态')
          return;
        } else {
          this.loading = true;
          this.type == "edit"
            ? this.updatePreTask(commData)
            : this.savePreTask(commData);
        }
      }
    },
    //已存在隔离人员弹框关闭(关闭弹窗不做提交处理)
    closeDialog() {
      this.dialogVisible = false;
    },

    //点击取消关闭弹窗 
    // cancelDialog(){
      
    // },


    confirmCloseDialog() {
      this.dialogVisible = false;
      this.loading = true;
      this.type == "edit"
        ? this.updatePreTask(this.commData)
        : this.savePreTask(this.commData);
    },

    //保存
    async savePreTask(commData) {
      const params = commData;
      const { data } = await savePreTaskApi(params);
      if (data.code == "200") {
        this.loading = false;
        this.$message.success("保存成功");
        this.$parent.isAddTask = false;
        this.$parent.getPreTaskPage();
      }
    },
    //修改保存
    async updatePreTask(commData) {
      const params = commData;
      const { data } = await updatePreTaskApi(params);
      if (data.code == "200") {
        this.loading = false;
        this.$message.success("保存成功");
        this.$parent.isAddTask = false;
        this.$parent.getPreTaskPage();
      }
    },
    //提交业务逻辑校验
    verifyFormInfo() {
      if (this.$refs.residentBasicInfoRef.userListForm.userList.length == 0) {
        this.$message.warning("需添加调查居民信息");
        return false;
      }
      return true;
    },
    //数据处理
    handelCommData() {
      const info = { ...this.taskInfo };
      //信息来源
      info.infoSourceCode = info.infoSourceCode.toString();
      const userList =
        this.$refs.residentBasicInfoRef.userListForm.userList.map((item) => {
          return {
            id: item.id ? item.id : "",
            name: item.name,
            cardType: item.cardType,
            cardNo: item.cardNo,
            linkPhone: item.linkPhone,
            placeList: item.placeList,
            crowdTypeId: item.crowdTypeId,
            crowdTypeCode: item.crowdTypeCode,
            crowdTypeName: item.crowdTypeName,
            surveyGroupId: item.surveyGroupId,
            surveyUserId: item.surveyUserId,
            surveyUserName: item.surveyUserName,
            surveyStatus: item.surveyStatus,
          };
        });
      //文件处理
      const fileList = this.tempFileList.map((item) => {
        return {
          fileName: item.name,
          fileUrl: item.url,
        };
      });
      const formInfo = { info, userList, fileList: fileList };
      return formInfo;
    },
    //展示详情
    showDetail(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }
  }

  .top_right {
    font-size: 12px;
    color: #9a9a9a;

    .xinxin {
      color: #ff5050;
    }
  }

  .content-item {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .formitem {
    width: 50%;
  }

  .select-width {
    width: 80%;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  .blue {
    width: 4px;
    height: 20px;
    background-color: #3476f5;
    margin-right: 6px;
  }

  .special {
    flex: 1;
  }

  .el-upload-tip {
    margin-left: 10px;
    color: #606266;
  }
}

.other-input {
  width: 200px;
}

.submit-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.warning {
  margin-bottom: 20px;
  padding-left: 20px;
}

.notification{
  display: flex;
  flex-wrap: nowrap;
  width: 80%;
  margin: 20px auto;
  justify-content: space-around;
  .NameNotification{
    width: 12%;
  }
  .CardIdNotification{
    width: 30%;
  }

  .OrgPhoneNotification{
    width: 30%;
  }

  .TypeNotification{
    width: 20%;
  }
}
</style>